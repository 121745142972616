import React from "react";

const Download = () => {
  const [DownloadData, setDownloadData] = React.useState({});
  const getreviewssData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    fetch("/Download-data.json", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setDownloadData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        alert("Hash copied success");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  React.useEffect(() => {
    getreviewssData();
  }, []);
  return (
    <div className="container mx-auto mt-5 px-5 flex flex-col items-center mb-5">
      <p className="text-3xl lg:w-1/2 text-center font-bold">Download</p>
      <div className="grid grid-cols-1 justify-between gap-6 mt-16 flex-col lg:grid-cols-3">
        <div>
          <div class="lg:justify-between flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border  shadow border-gray-600 xl:p-8 bg-gray-800 text-white lg:min-h-[500px]">
            <div className="flex flex-col items-center mb-4">
              <div className="w-16 h-16 bg-secondary/20 rounded-xl flex items-center justify-center mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
              </div>
              <h3 class="text-2xl font-semibold">HydraFlasher <span className="text-secondary">Desktop</span></h3>
              <div className="w-16 h-1 bg-secondary rounded-full mt-2"></div>
            </div>
            <p class="font-light sm:text-lg text-gray-400 mb-3">
              Hydra flasher is a powerFull bitcoin flashing tools. It helps you
              to Flash Your Bitcoin Wallet up to 100 Bitcoin in a day.
            </p>

            <div className="grid grid-cols-1 gap-4">
           
              {/* <a
                className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                href={DownloadData.hydraflasher_demo}
                target={"_blank"}
              >
                Download Demo
              </a> */}
              <a
                className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                href={DownloadData.hydraflasher}
                target={"_blank"}
              >
                Download 
              </a>
            </div>
          </div>
        </div>

        <div>
          <div class="lg:justify-between flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border  shadow border-gray-600 xl:p-8 bg-gray-800 text-white lg:min-h-[500px]">
            <div className="flex flex-col items-center mb-4">
              <div className="w-16 h-16 bg-secondary/20 rounded-xl flex items-center justify-center mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                </svg>
              </div>
              <h3 class="text-2xl font-semibold">HydraFlasher <span className="text-secondary">Mobile</span></h3>
              <div className="w-16 h-1 bg-secondary rounded-full mt-2"></div>
            </div>
            <p class="font-light sm:text-lg text-gray-400 mb-3">
              Hydra flasher mobile app is a powerFull bitcoin flashing tools. It
              helps you to Flash Your Bitcoin Wallet up to 100 Bitcoin in a day
              from your mobile. Min android version 5.0 required.
            </p>

          
            {/* <a
                className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                href={DownloadData.hydraflasher_mobile_demo}
                target={"_blank"}
              >
                Download Demo
              </a> */}

            <a
              className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
              href={DownloadData.hydraflasher_mobile}
              target={"_blank"}
            >
              Download 
            </a>

            <a
                className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                href={DownloadData.hydraflasher_mobile_antminer}
                target={"_blank"}
              >
                Download AntMiner
              </a>
          </div>
        </div>

        <div>
          <div class="lg:justify-between flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border  shadow border-gray-600 xl:p-8 bg-gray-800 text-white lg:min-h-[500px]">
            <h3 class="mb-4 text-2xl font-semibold">Bitcoin Core 5.0.1-V9</h3>
            <p class="font-light sm:text-lg text-gray-400 mb-3">
              Bitcoin Core is programmed to decide which block chain contains
              valid transactions. The users of Bitcoin Core only accept
              transactions for that block chain, making it the Bitcoin block
              chain that everyone else wants to use.
            </p>

            <a
              className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
              href={DownloadData.bitcoin_core}
              target={"_blank"}
            >
              Download
            </a>
          </div>
        </div>

        <div>
          <div class="lg:justify-between flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border shadow border-gray-600 xl:p-8 bg-gray-800 text-white lg:min-h-[500px]">
            <div className="flex flex-col items-center mb-4">
              <div className="w-16 h-16 bg-secondary/20 rounded-xl flex items-center justify-center mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <h3 class="text-2xl font-semibold">API <span className="text-secondary">Access Hash</span></h3>
              <div className="w-16 h-1 bg-secondary rounded-full mt-2"></div>
            </div>
            
            <div className="flex flex-col space-y-4">
              <div className="bg-gray-900/50 rounded-lg p-4">
                <p className="font-light text-sm text-gray-400 mb-2">
                  This hash key is exclusively for API access. Required for connecting the Hydra Flasher API to our secure network.
                </p>
                <div className="relative">
                  <div className="bg-gray-700 rounded-lg p-3 text-sm text-gray-300 font-mono break-all">
                    {DownloadData.copy_hash}
                  </div>
                  <div className="absolute top-2 right-2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        copyTextToClipboard(DownloadData.copy_hash);
                      }}
                      className="p-1.5 bg-secondary/10 hover:bg-secondary/20 rounded-md transition-colors"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

   

              <a
                onClick={(e) => {
                  e.preventDefault();
                  copyTextToClipboard(DownloadData.copy_hash);
                }}
                className="buy-with-crypto bg-secondary focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900 mt-4"
                href="#"
              >
                Copy Hash Key
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
