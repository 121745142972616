import Banner from "../banner/Banner";
import { Featured } from "../featured/Featured";
import { Price } from "../price/Price";
import { Reviews } from "../Reviews/Reviews";
import Stat from "../Stat/Stat";
import EidSaleSection from "../marketing/EidSaleSection";

const Home = () => {
  return (
    <div className="scroolbar-hide">
      <div className="mt-[100px]">
        <Banner></Banner>
      </div>
      <div className="mt-[50px]">
        <EidSaleSection />
      </div>
      <div className="mt-[100px]">
        <Featured></Featured>
      </div>
      <div className="mt-[100px]">
        <Stat></Stat>
      </div>
      <div className="mt-[100px]">
        <Price />
      </div>
      <div className="mt-[150px]">
        <Reviews />
      </div>
    </div>
  );
};

export default Home;
