import { PriceCard } from "./PriceCard";
import { PriceCardNotAvailable } from "./PriceCard-notavailable,";
import EidSalePricing from "../marketing/EidSalePricing";

export const Price = () => {
  return (
    <div className="container mx-auto px-5 flex flex-col items-center mb-5">
      <p className="text-2xl lg:w-1/2 text-center font-bold">Our Products</p>
      <EidSalePricing />
      <div className="flex justify-between gap-6 mt-16 flex-col lg:flex-row">
        <PriceCard
          key={2}
          isOffer={true}
          details={{
            title: "Hydra Flasher",
            header: "Best option for personal use for 2 months Validity",
            price: "950",
            offerPrice: "199",
            lifetime: false,
            infos: [
              "Easy Setup",
              "No setup, or hidden fees",
              // "Daily Limit 1 Bitcoin",
              "Daily Limit 5 Bitcoin",
              "Trade and Transfer Facility",
              // "Customer support: 7 days",
              "Customer support: 2 months",
              "Free updates: 2 months",
              "1 License Key For Single Device",
            ],
            product_type: "HYDRAFLASHER",
          }}
        />
        <PriceCard
          key={2}
          details={{
            title: "Hydra Flasher Premium",
            header: "Best option for professional use for 4 month Validity",
            price: "1700",
            lifetime: false,
            infos: [
              "Easy Setup",
              "No setup, or hidden fees",
              "Daily Limit 10 Bitcoin",
              "Trade and Transfer Facility",
              "Premium support: 4 months",
              "Free updates: 4 months",
              "1 License Key For Single Device",
            ],
            product_type: "HYDRAFLASHER_PREMIUM",
          }}
        />
        <PriceCard
          key={2}
          details={{
            title: "Hydra Script (API Access)",
            header: "For 10 days",
            price: "25,000",
            lifetime: false,
            infos: [
              "Setup (For Debian Linux and Windows)",
              "Command-line Interface",
              'Daily Flash Limit: "No Limitation"',
              "Trade and Transfer Facility",
              "Free Setup For First time",
              "Free updates: Subscription Time Only.",
              "1 License Key For Single Device",
            ],
            product_type: "HYDRAFLASHER_API_ACCESS",
          }}
        />
        <PriceCardNotAvailable
          key={2}
          details={{
            title: "Bullet Proof RDP",
            header: "Best option for personal use for 2 Month.",
            pricePreffix: "",
            price: "250",
            lifetime: false,
            infos: [
              "Pre Configured",
              "No setup, or hidden fees",
              "Admin Access",
              "Premium support: 2 months",
              "6 core 16 GB Ram",
              "1 License Key For Single Device",
              "Singapore Server",
            ],
            product_type: "BULLET_PRROF_RDP",
          }}
        />
      </div>
    </div>
  );
};
