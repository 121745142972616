import React, { useState } from "react";
import { PriceCard } from "../price/PriceCard";
import { PriceCardNotAvailable } from "../price/PriceCard-notavailable,";

export const Shop = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [termsAccepted, setTermAccepted] = React.useState(false);
  const [showExpiredModal, setShowExpiredModal] = React.useState(false);

  return (
    <div className="container mx-auto px-5 flex flex-col items-center my-16">
      <p className="text-3xl lg:w-1/2 text-center font-bold">Our Products</p>
      <p className="mt-2 mb-3">To get a free demo flash, submit a request.</p>

      {/* Original demo request button - commented out */}
      {/* <button
        onClick={() => {
          setShowModal(true);
        }}
        className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
      >
        Request a demo
      </button> */}

      {/* New button for expired demo message */}
      <button
        onClick={() => {
          setShowExpiredModal(true);
        }}
        className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
      >
        Request a demo
      </button>

      {/* New modal for expired demo message */}
      <div
        className={`${
          showExpiredModal ? "flex" : "hidden"
        } bg-[#0000003c] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex justify-between items-center p-5 rounded-t border-b dark:border-gray-600 bg-gray-50">
              <div className="flex items-center">
                <div className="p-2 bg-orange-100 rounded-full mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Demo Offer Expired
                </h3>
              </div>
              <button
                onClick={() => {
                  setShowExpiredModal(false);
                }}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="p-6">
              <div className="mb-6">
                <p className="text-base leading-relaxed text-gray-600 dark:text-gray-400 mb-4">
                  The demo offer is no longer available. To start using Hydra Flasher, please choose from our premium packages below.
                </p>
                <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span className="text-sm text-gray-600">Full access to all features</span>
                    </li>
                    <li className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span className="text-sm text-gray-600">Premium customer support</span>
                    </li>
                    <li className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span className="text-sm text-gray-600">Regular updates included</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowExpiredModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setShowExpiredModal(false);
                    // Scroll to pricing section smoothly
                    document.querySelector('.flex.justify-between.gap-6').scrollIntoView({ behavior: 'smooth' });
                  }}
                  className="buy-with-crypto bg-secondary hover:bg-opacity-90 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900 flex items-center"
                >
                  View Packages
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class={`${
          showModal ? "flex" : "hidden"
        } bg-[#0000003c] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
      >
        <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Terms and conditions
              </h3>
              <button
                onClick={() => {
                  setShowModal(false);
                }}
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="defaultModal"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>

            <div class="p-6 space-y-6">
              <div className="max-h-[200px] overflow-auto border-2 p-3">
                <p className="terms-text">
                  As a condition of use, you promise not to use the HydraFlasher
                  Demo Services for any purpose that is unlawful or prohibited
                  by these Terms. <br />
                  By way of example. and not as a limitatian. you agree not to
                  use the Services: <br />
                  For Demo flash, you have to submit a request to server
                  admistrator for a Demo flash.
                  <br /> We will provide a free license to every new user. By
                  this free license, user can flash 0.0001 BTC in there own
                  wallet for free of cost (user can Flash There own). <br />
                  Hydra Flasher Representative will never ask Anydesk or Team
                  Viewer for demo flash.
                  <br />
                  User can Flash One Time with that Demo License. After Flashing
                  the Demo, License will Automatically Deactive. <br />
                  For Continue or Active a Package License, user must go through
                  for a paid License From Shop.
                  <br />
                  Paid License will available in our Shop section. Our old
                  Sellix Shop is closed.
                </p>
              </div>

              <div class="flex items-center mb-4">
                <input
                  onChange={(e) => {
                    setTermAccepted(e.target.checked);
                  }}
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />

                <label
                  for="default-checkbox"
                  class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  I Agree to terms and conditions.
                </label>

                <br />
              </div>

              <button
                onClick={() => {
                  setShowModal(false);
                  setShowModal2(true);
                }}
                className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                disabled={termsAccepted ? false : true}
              >
                Request a demo
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class={`${
          showModal2 ? "flex" : "hidden"
        } bg-[#0000003c] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
      >
        <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Submit Request
              </h3>
              <button
                onClick={() => {
                  setShowModal2(false);
                }}
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="defaultModal"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>

            <div class="p-6 space-y-6">
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="name@flowbite.com"
                  required
                />
              </div>

              <p>Request a demo by</p>
              <p>
                <a
                  href="mailto:hydrabtcflasher@gmail.com"
                  className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                >
                  Email
                </a>{" "}
                or{" "}
                <a
                  target={"_blank"}
                  href="https://t.me/hydraflasher

"
                  className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                >
                  Telegram
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between gap-6 mt-16 lg:flex-row flex-col">
        <PriceCard
          key={2}
          details={{
            title: "Hydra Flasher",
            header: "Best option for personal use for 2 month Validity",
            price: "950",
            lifetime: false,
            infos: [
              "Easy Setup",
              "No setup, or hidden fees",
              "Daily Limit 5 Bitcoin",
              "Trade and Transfer Facility",
              "Customer support: 2 months",
              "Free updates: 2 months",
              "1 License Key For Single Device",
            ],
            product_type: "HYDRAFLASHER",
          }}
        />
        <PriceCard
          key={2}
          details={{
            title: "Hydra Flasher Premium",
            header: "Best option for professional use for 4 month Validity",
            price: "1700",
            lifetime: false,
            infos: [
              "Easy Setup",
              "No setup, or hidden fees",
              "Daily Limit 10 Bitcoin",
              "Trade and Transfer Facility",
              "Premium support: 4 months",
              "Free updates: 4 months",
              "1 License Key For Single Device",
            ],
            product_type: "HYDRAFLASHER_PREMIUM",
          }}
        />
        <PriceCard
          key={2}
          details={{
            title: "Hydra Script (API Access)",
            header: "For 10 days",
            price: "25,000",
            lifetime: false,
            infos: [
              "Setup (For Debian Linux and Windows)",
              "Command-line Interface",
              'Daily Flash Limit: "No Limitation"',
              "Trade and Transfer Facility",
              "Free Setup For First time",
              "Free updates: Subscription Time Only.",
              "1 License Key For Single Device",
            ],
            product_type: "HYDRAFLASHER_API_ACCESS",
          }}
        />
        <PriceCardNotAvailable
          key={2}
          details={{
            title: "Bullet Proof RDP",
            header: "Best option for personal use for 2 Month.",
            pricePreffix: "",
            price: "250",
            lifetime: false,
            infos: [
              "Pre Configured",
              "No setup, or hidden fees",
              "Admin Access",
              "Premium support: 2 months",
              "6 core 16 GB Ram",
              "1 License Key For Single Device",
              "Singapore Server",
            ],
            product_type: "BULLET_PRROF_RDP",
          }}
        />
      </div>
    </div>
  );
};
